import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <h1 className='transparent-blur-bigtitle'>Devonian</h1>
          <h3 className='main-subtitle'>Prove your claims</h3>
        <h3 className='main-subtitle-email'>team@devonian.ai</h3>
      </header>
    </div>
  );
}

export default App;
